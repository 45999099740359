@import url("//fonts.cdnfonts.com/css/sf-pro-display");

body {
    font-family: "SF Pro Display", sans-serif;
    margin: 0;
    background-color: #171931;
    color: white;
    display: flex;
    letter-spacing: 0.03rem;
}

#root {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: space-between;
    flex-direction: column;
    flex-grow: 1;
}

.react-calendar {
    background-color: transparent !important;
    margin: 0 auto;
    border: 1px solid #171931 !important;
}

.react-calendar>button:hover {
    background: #171931 !important;
}

.react-calendar__tile:disabled {
    background: inherit !important;
    opacity: 0.4 !important;
}

.react-calendar__tile:hover {
    background: rgba(67, 133, 243, 1) !important;
    border-radius: 50%;
}

.react-calendar__tile--now {
    background: #FFC700 !important;
    border-radius: 50%;
}

.react-calendar__tile--active {
    border-radius: 50%;
    background: #42A776 !important;
}

.react-calendar__month-view__days__day--weekend {
    color: inherit !important;
}

button {
    color: white;
}

.rbc-row-segment .rbc-event-content {
    word-wrap: break-word;
    white-space: break-spaces !important;
}

.rbc-label,
.rbc-header {
    color: black;
}

.rbc-calendar,
.rbc-toolbar {
    font-size: 12px;
}

.rbc-timeslot-group {
    min-height: 27px;
}

.rbc-current-time-indicator {
    background-color: #b53434 !important;
    height: 2px !important;
}

.rbc-off-range-bg {
    /* set background for off days here */
}

a:visited,
a:hover,
a {
    text-decoration: none;
    color: #4385F3 !important;
}

.tooltip {
    color: black !important;
}

/* .besafe-calendar.rbc-calendar,  
.besafe-calendar.rbc-calendar .rbc-label,
.besafe-calendar.rbc-calendar .rbc-header,
.besafe-calendar.rbc-calendar .rbc-toolbar
{
    color: white !important;
    background-color: rgba(41, 44, 84, 1);
}

.besafe-calendar.rbc-calendar .rbc-today
{
    background-color: rgba(23, 25, 49, 1);
}

.slot-default {
    border: none;
} */


.map-label,
.map-notification {
    border: 1px solid #fff;
    font-family: "SF Pro Display, sans-serif";
    border: 1px solid rgba(96, 96, 96, 1);
    background: rgba(96, 96, 96, 1);
    color: #fff !important;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    font-size: 11px !important;
    width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.map-notification {
    background: rgba(181, 52, 52, 1);
    border: 1px solid rgba(181, 52, 52, 1);
}
